import list from 'src/modules/userPermissions/list/userPermissionsListReducers';
import form from 'src/modules/userPermissions/form/userPermissionsFormReducers';
import view from 'src/modules/userPermissions/view/userPermissionsViewReducers';
import destroy from 'src/modules/userPermissions/destroy/userPermissionsDestroyReducers';
import importerReducer from 'src/modules/userPermissions/importer/userPermissionsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
