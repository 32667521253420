import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userPermissions/importer/userPermissionsImporterSelectors';
import UserPermissionsService from 'src/modules/userPermissions/userPermissionsService';
import fields from 'src/modules/userPermissions/importer/userPermissionsImporterFields';
import { i18n } from 'src/i18n';

const userPermissionsImporterActions = importerActions(
  'USERPERMISSIONS_IMPORTER',
  selectors,
  UserPermissionsService.import,
  fields,
  i18n('entities.userPermissions.importer.fileName'),
);

export default userPermissionsImporterActions;