import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/assetTypes/importer/assetTypesImporterSelectors';
import AssetTypesService from 'src/modules/assetTypes/assetTypesService';
import fields from 'src/modules/assetTypes/importer/assetTypesImporterFields';
import { i18n } from 'src/i18n';

const assetTypesImporterActions = importerActions(
  'ASSETTYPES_IMPORTER',
  selectors,
  AssetTypesService.import,
  fields,
  i18n('entities.assetTypes.importer.fileName'),
);

export default assetTypesImporterActions;