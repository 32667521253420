import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.userPermissions.fields.id'),
  },
  {
    name: 'userId',
    label: i18n('entities.userPermissions.fields.userId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'assetId',
    label: i18n('entities.userPermissions.fields.assetId'),
    render: exporterRenders.relationToOne(),
  },
  // {
  //   name: 'locationId',
  //   label: i18n('entities.userPermissions.fields.locationId'),
  //   render: exporterRenders.relationToOne(),
  // },
  // {
  //   name: 'buildingId',
  //   label: i18n('entities.userPermissions.fields.buildingId'),
  //   render: exporterRenders.relationToOne(),
  // },
  // {
  //   name: 'floorId',
  //   label: i18n('entities.userPermissions.fields.floorId'),
  //   render: exporterRenders.relationToOne(),
  // },
  {
    name: 'createdAt',
    label: i18n('entities.userPermissions.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.userPermissions.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
