import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.orders.fields.id'),
  },
  // {
  //   name: 'legacyId',
  //   label: i18n('entities.orders.fields.legacyId'),
  // },
  {
    name: 'name',
    label: i18n('entities.orders.fields.name'),
  },
  {
    name: 'description',
    label: i18n('entities.orders.fields.description'),
  },
  {
    name: 'assetType',
    label: i18n('entities.orders.fields.assetType'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'assetSubtype',
    label: i18n('entities.orders.fields.assetSubtype'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'telemetryKeys',
    label: i18n('entities.orders.fields.telemetryKeys'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'icon',
    label: i18n('entities.orders.fields.icon'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'secrets',
    label: i18n('entities.orders.fields.secrets'),
  },
  {
    name: 'config',
    label: i18n('entities.orders.fields.config'),
  },
  {
    name: 'extra',
    label: i18n('entities.orders.fields.extra'),
  },
  {
    name: 'sensors',
    label: i18n('entities.orders.fields.sensors'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'location',
    label: i18n('entities.orders.fields.location'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'building',
    label: i18n('entities.orders.fields.building'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'floor',
    label: i18n('entities.orders.fields.floor'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.orders.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.orders.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
