import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/firmwares/importer/firmwaresImporterSelectors';
import FirmwaresService from 'src/modules/firmwares/firmwaresService';
import fields from 'src/modules/firmwares/importer/firmwaresImporterFields';
import { i18n } from 'src/i18n';

const firmwaresImporterActions = importerActions(
  'FIRMWARES_IMPORTER',
  selectors,
  FirmwaresService.import,
  fields,
  i18n('entities.firmwares.importer.fileName'),
);

export default firmwaresImporterActions;