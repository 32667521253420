import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class DashboardsService {
  // static async update(id, data) {
  //   const body = {
  //     id,
  //     data,
  //   };

  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.put(
  //     `/tenant/${tenantId}/dashboards/${id}`,
  //     body,
  //   );

  //   return response.data;
  // }

  // static async destroyAll(ids) {
  //   const params = {
  //     ids,
  //   };

  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.delete(
  //     `/tenant/${tenantId}/dashboards`,
  //     {
  //       params,
  //     },
  //   );

  //   return response.data;
  // }

  // static async create(data) {
  //   const body = {
  //     data,
  //   };

  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.post(
  //     `/tenant/${tenantId}/dashboards`,
  //     body,
  //   );

  //   return response.data;
  // }

  // static async import(values, importHash) {
  //   const body = {
  //     data: values,
  //     importHash,
  //   };

  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.post(
  //     `/tenant/${tenantId}/dashboards/import`,
  //     body,
  //   );

  //   return response.data;
  // }

  // static async find(id) {
  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.get(
  //     `/tenant/${tenantId}/dashboards/${id}`,
  //   );

  //   return response.data;
  // }

  static async list() {
    const params = {
      // filter,
      // orderBy,
      // limit,
      // offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/dashboards`,
      {
        params,
      },
    );
    // const response={
    //   data:{
    //       rows: [
    //           {
    //               "id": "540581b6-efa8-4474-9896-8b002965bc1d",
    //               "legacyId": 2,
    //               "layout": [
    //                 { i: 'ee7e94e5-7ea4-4a5e-8c48-14de5baf12b0', x: 0, y: 0, w: 5, h: 7 ,minW:5,minH:7},
    //                 { i: '4416b1c9-f88c-4a10-be81-be73ce33d6d6', x: 0, y: 0, w: 5, h: 7 ,minW:5,minH:7},
    //               ],
    //               "states": "active",
    //               "remarks": "done",
    //               "importHash": null,
    //               "createdAt": "2024-05-16T13:24:44.606Z",
    //               "updatedAt": "2024-05-16T13:24:44.606Z",
    //               "deletedAt": null,
    //               "userId": "1062321e-de1c-4248-bfcb-69559756391b",
    //               "tenantId": "30fc8580-45cc-4bad-95ba-9432318233a9",
    //               "createdById": "18af54f4-cc49-425c-971c-7d92fbc77d3e",
    //               "updatedById": "18af54f4-cc49-425c-971c-7d92fbc77d3e",
    //               "widgets": [
    //                   {
    //                       "id": "ee7e94e5-7ea4-4a5e-8c48-14de5baf12b0",
    //                       "legacyId": 1,
    //                       "quantity": 3,
    //                       "type":"lineChart",
    //                       "data":[1,2,3,4,5,6,7],
    //                       "labels":['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //                       "datasets":[
    //                         {
    //                           "label": 'Line Chart',
    //                           "data": [],
    //                           "fill": false,
    //                           "lineTension": 0.1,
    //                           "backgroundColor": 'rgba(75, 192, 192, 0.4)',
    //                           "borderColor": 'rgba(75, 192, 192, 1)',
    //                           "borderWidth": 1,
    //                         },
    //                       ],
    //                       "options": {
    //                           "responsive": true,
    //                           "interaction": {
    //                             "mode": 'index',
    //                             "intersect": false,
    //                           },
    //                           "stacked": false,
    //                           "plugins": {
    //                             "title": {
    //                               "display": true,
    //                               "text": 'Chart.js Line Chart - Multi Axis',
    //                             },
    //                           },
    //                           "scales": {
    //                             "y": {
    //                               "type": 'linear',
    //                               "display": true,
    //                               "position": 'left',
    //                             },
    //                             "y1": {
    //                               "type": 'linear',
    //                               "display": true,
    //                               "position": 'right',
    //                               "grid": {
    //                                 "drawOnChartArea": false,
    //                               },
    //                             },
    //                           },
    //                       },
    //                       "importHash": null,
    //                       "createdAt": "2024-05-16T13:24:44.620Z",
    //                       "updatedAt": "2024-05-16T13:24:44.620Z",
    //                       "deletedAt": null,
    //                       "tenantId": "30fc8580-45cc-4bad-95ba-9432318233a9",
    //                       "createdById": "18af54f4-cc49-425c-971c-7d92fbc77d3e",
    //                       "updatedById": "18af54f4-cc49-425c-971c-7d92fbc77d3e"
    //                   },
    //                   {
    //                       "id": "4416b1c9-f88c-4a10-be81-be73ce33d6d6",
    //                       "legacyId": 2,
    //                       "quantity": 3,
    //                       "type":"barChart",
    //                       "data":[1,2,3,4,5],
    //                       "labels":['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //                       "datasets":[
    //                         {
    //                           "label": 'Bar Chart',
    //                           "data": [],
    //                           "backgroundColor": 'rgba(255, 99, 132, 0.2)',
    //                           "borderColor": 'rgba(255, 99, 132, 1)',
    //                           "borderWidth": 1,
    //                         },
    //                       ],
    //                       "importHash": null,
    //                       "createdAt": "2024-05-16T13:24:44.620Z",
    //                       "updatedAt": "2024-05-16T13:24:44.620Z",
    //                       "deletedAt": null,
    //                       "tenantId": "30fc8580-45cc-4bad-95ba-9432318233a9",
    //                       "createdById": "18af54f4-cc49-425c-971c-7d92fbc77d3e",
    //                       "updatedById": "18af54f4-cc49-425c-971c-7d92fbc77d3e"
    //                   }
    //               ]
    //           },
    //       ],
    //       "count": 3
        
    //   }
    // }

    return response.data;
  }

  // static async listAutocomplete(query, limit) {
  //   const params = {
  //     query,
  //     limit,
  //   };

  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.get(
  //     `/tenant/${tenantId}/dashboards/autocomplete`,
  //     {
  //       params,
  //     },
  //   );

  //   return response.data;
  // }
}
