import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  // {
  //   name: 'legacyId',
  //   label: i18n('entities.assetTypes.fields.legacyId'),
  //   schema: schemas.integer(
  //     i18n('entities.assetTypes.fields.legacyId'),
  //     {
  //       "required": true
  //     },
  //   ),
  // },
  {
    name: 'name',
    label: i18n('entities.assetTypes.fields.name'),
    schema: schemas.string(
      i18n('entities.assetTypes.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'extra',
    label: i18n('entities.assetTypes.fields.extra'),
    schema: schemas.string(
      i18n('entities.assetTypes.fields.extra'),
      {},
    ),
  },
];