import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'userId',
    label: i18n('entities.userPermissions.fields.userId'),
    schema: schemas.relationToOne(
      i18n('entities.userPermissions.fields.userId'),
      {},
    ),
  },
  {
    name: 'assetId',
    label: i18n('entities.userPermissions.fields.assetId'),
    schema: schemas.relationToOne(
      i18n('entities.userPermissions.fields.assetId'),
      {},
    ),
  },
  // {
  //   name: 'locationId',
  //   label: i18n('entities.userPermissions.fields.locationId'),
  //   schema: schemas.relationToOne(
  //     i18n('entities.userPermissions.fields.locationId'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'buildingId',
  //   label: i18n('entities.userPermissions.fields.buildingId'),
  //   schema: schemas.relationToOne(
  //     i18n('entities.userPermissions.fields.buildingId'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'floorId',
  //   label: i18n('entities.userPermissions.fields.floorId'),
  //   schema: schemas.relationToOne(
  //     i18n('entities.userPermissions.fields.floorId'),
  //     {},
  //   ),
  // },
];