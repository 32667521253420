import actions from 'src/modules/dashboards/dashboardsActions';

const INITIAL_PAGE_SIZE = 10;

const initialData = {
  rows: [] as Array<any>,
  widgets: [] as Array<any>,
  layouts: [] as Array<any>,
  // count: 0,
  loading: false,
  // filter: {},
  // rawFilter: {},
  // widgets: [],
  // pagination: {
  //   current: 1,
  //   pageSize: INITIAL_PAGE_SIZE,
  // },
  // sorter: {},
  // selectedKeys: [] as Array<any>,
};

export default (state = initialData, { type, payload }) => {

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      // selectedKeys: [],
      // filter: payload ? payload.filter : {},
      // rawFilter: payload ? payload.rawFilter : {},
      // pagination:
      //   payload && payload.keepPagination
      //     ? state.pagination
      //     : {
      //         current: 1,
      //         pageSize: INITIAL_PAGE_SIZE,
      //       },
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      widgets: payload.widgets ? payload.widgets : [],
      layouts: payload.layouts ? payload.layouts : [],
      // count: payload.count,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      widgets: [],
      layouts: [],
      // count: 0,
    };
  }
  // if (type === actions.LAYOUT_CHANGED) {
  //   return {
  //     ...state,
  //     layouts: payload||[],
  //   };
  // }

  return state;
};
