import DashboardsService from 'src/modules/dashboards/dashboardsService';
import selectors from 'src/modules/dashboards/dashboardsSelectors';
import { i18n } from 'src/i18n';
// import exporterFields from 'src/modules/dashboards/list/dashboardsListExporterFields';
import Errors from 'src/modules/shared/error/errors';
// import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'DASHBOARDS';

const dashboardsActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  // LAYOUT_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  // RESETED: `${prefix}_RESETED`,
  // TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  // TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  // CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  // PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  // SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  // EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  // EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  // EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  // doClearAllSelected() {
  //   return {
  //     type: dashboardsActions.CLEAR_ALL_SELECTED,
  //   };
  // },

  // doToggleAllSelected() {
  //   return {
  //     type: dashboardsActions.TOGGLE_ALL_SELECTED,
  //   };
  // },

  // doToggleOneSelected(id) {
  //   return {
  //     type: dashboardsActions.TOGGLE_ONE_SELECTED,
  //     payload: id,
  //   };
  // },

  // doReset: () => async (dispatch) => {
  //   dispatch({
  //     type: dashboardsActions.RESETED,
  //   });

  //   dispatch(dashboardsActions.doFetch());
  // },

  // doExport: () => async (dispatch, getState) => {
  //   try {
  //     if (!exporterFields || !exporterFields.length) {
  //       throw new Error('exporterFields is required');
  //     }

  //     dispatch({
  //       type: dashboardsActions.EXPORT_STARTED,
  //     });

  //     const filter = selectors.selectFilter(getState());
  //     const response = await DashboardsService.list(
  //       filter,
  //       selectors.selectOrderBy(getState()),
  //       null,
  //       null,
  //     );

  //     new Exporter(
  //       exporterFields,
  //       i18n('entities.dashboards.exporterFileName'),
  //     ).transformAndExportAsExcelFile(response.rows);

  //     dispatch({
  //       type: dashboardsActions.EXPORT_SUCCESS,
  //     });
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch({
  //       type: dashboardsActions.EXPORT_ERROR,
  //     });
  //   }
  // },

  // doChangePagination: (pagination) => async (
  //   dispatch,
  //   getState,
  // ) => {
  //   dispatch({
  //     type: dashboardsActions.PAGINATION_CHANGED,
  //     payload: pagination,
  //   });

  //   dispatch(dashboardsActions.doFetchCurrentFilter());
  // },

  // doChangeLayout: (layout) => async (
  //   dispatch,
  //   getState,
  //   ) => {
  //     dispatch({
  //       type: dashboardsActions.LAYOUT_CHANGED,
  //       payload: layout,
  //     });

  //     // dispatch(dashboardsActions.doFetchCurrentFilter());
  //   },

  // doChangeSort: (sorter) => async (dispatch, getState) => {
  //   dispatch({
  //     type: dashboardsActions.SORTER_CHANGED,
  //     payload: sorter,
  //   });

  //   dispatch(dashboardsActions.doFetchCurrentFilter());
  // },

  // doFetchCurrentFilter: () => async (
  //   dispatch,
  //   getState,
  // ) => {
  //   const filter = selectors.selectFilter(getState());
  //   const rawFilter = selectors.selectRawFilter(getState());
  //   dispatch(dashboardsActions.doFetch(filter, rawFilter, true));
  // },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: dashboardsActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await DashboardsService.list(
        // filter,
        // selectors.selectOrderBy(getState()),
        // selectors.selectLimit(getState()),
        // selectors.selectOffset(getState()),
      );

      dispatch({
        type: dashboardsActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          widgets: response.rows[0].widgets ? response.rows[0].widgets : [],
          layouts: response.rows[0].layout ? response.rows[0].layout : [],
          // count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardsActions.FETCH_ERROR,
      });
    }
  },
};

export default dashboardsActions;
