import list from 'src/modules/orderItems/list/orderItemsListReducers';
import form from 'src/modules/orderItems/form/orderItemsFormReducers';
import view from 'src/modules/orderItems/view/orderItemsViewReducers';
import destroy from 'src/modules/orderItems/destroy/orderItemsDestroyReducers';
import importerReducer from 'src/modules/orderItems/importer/orderItemsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
