import list from 'src/modules/notifications/list/notificationsListReducers';
import form from 'src/modules/notifications/form/notificationsFormReducers';
import view from 'src/modules/notifications/view/notificationsViewReducers';
import destroy from 'src/modules/notifications/destroy/notificationsDestroyReducers';
import importerReducer from 'src/modules/notifications/importer/notificationsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
