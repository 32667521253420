import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.notifications.fields.id'),
  },
  // {
  //   name: 'legacyId',
  //   label: i18n('entities.notifications.fields.legacyId'),
  // },
  {
    name: 'type',
    label: i18n('entities.notifications.fields.type'),
  },
  {
    name: 'severity',
    label: i18n('entities.notifications.fields.severity'),
  },
  {
    name: 'title',
    label: i18n('entities.notifications.fields.title'),
  },
  {
    name: 'body',
    label: i18n('entities.notifications.fields.body'),
  },
  {
    name: 'startTime',
    label: i18n('entities.notifications.fields.startTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endTime',
    label: i18n('entities.notifications.fields.endTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'customers',
    label: i18n('entities.notifications.fields.customers'),
  },
  {
    name: 'locations',
    label: i18n('entities.notifications.fields.locations'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'extra',
    label: i18n('entities.notifications.fields.extra'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.notifications.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.notifications.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
