import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'key',
    label: i18n('entities.orderItems.fields.key'),
    schema: schemas.integer(
      i18n('entities.orderItems.fields.key'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'name',
    label: i18n('entities.orderItems.fields.name'),
    schema: schemas.string(
      i18n('entities.orderItems.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'unit',
    label: i18n('entities.orderItems.fields.unit'),
    schema: schemas.string(
      i18n('entities.orderItems.fields.unit'),
      {},
    ),
  },
];