import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.assets.fields.id'),
  },
  // {
  //   name: 'legacyId',
  //   label: i18n('entities.assets.fields.legacyId'),
  // },
  {
    name: 'name',
    label: i18n('entities.assets.fields.name'),
  },
  {
    name: 'description',
    label: i18n('entities.assets.fields.description'),
  },
  {
    name: 'assetType',
    label: i18n('entities.assets.fields.assetType'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'assetSubtype',
    label: i18n('entities.assets.fields.assetSubtype'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'telemetryKeys',
    label: i18n('entities.assets.fields.telemetryKeys'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'icon',
    label: i18n('entities.assets.fields.icon'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'secrets',
    label: i18n('entities.assets.fields.secrets'),
  },
  {
    name: 'config',
    label: i18n('entities.assets.fields.config'),
  },
  {
    name: 'extra',
    label: i18n('entities.assets.fields.extra'),
  },
  {
    name: 'sensors',
    label: i18n('entities.assets.fields.sensors'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'location',
    label: i18n('entities.assets.fields.location'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'building',
    label: i18n('entities.assets.fields.building'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'floor',
    label: i18n('entities.assets.fields.floor'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.assets.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.assets.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
