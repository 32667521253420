import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  // {
  //   name: 'legacyId',
  //   label: i18n('entities.notifications.fields.legacyId'),
  //   schema: schemas.integer(
  //     i18n('entities.notifications.fields.legacyId'),
  //     {
  //       "required": true
  //     },
  //   ),
  // },
  {
    name: 'type',
    label: i18n('entities.notifications.fields.type'),
    schema: schemas.string(
      i18n('entities.notifications.fields.type'),
      {},
    ),
  },
  {
    name: 'severity',
    label: i18n('entities.notifications.fields.severity'),
    schema: schemas.string(
      i18n('entities.notifications.fields.severity'),
      {},
    ),
  },
  {
    name: 'title',
    label: i18n('entities.notifications.fields.title'),
    schema: schemas.string(
      i18n('entities.notifications.fields.title'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'body',
    label: i18n('entities.notifications.fields.body'),
    schema: schemas.string(
      i18n('entities.notifications.fields.body'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'startTime',
    label: i18n('entities.notifications.fields.startTime'),
    schema: schemas.datetime(
      i18n('entities.notifications.fields.startTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'endTime',
    label: i18n('entities.notifications.fields.endTime'),
    schema: schemas.datetime(
      i18n('entities.notifications.fields.endTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'customers',
    label: i18n('entities.notifications.fields.customers'),
    schema: schemas.string(
      i18n('entities.notifications.fields.customers'),
      {},
    ),
  },
  {
    name: 'locations',
    label: i18n('entities.notifications.fields.locations'),
    schema: schemas.relationToMany(
      i18n('entities.notifications.fields.locations'),
      {},
    ),
  },
  {
    name: 'extra',
    label: i18n('entities.notifications.fields.extra'),
    schema: schemas.string(
      i18n('entities.notifications.fields.extra'),
      {},
    ),
  },
];