import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/notifications/importer/notificationsImporterSelectors';
import NotificationsService from 'src/modules/notifications/notificationsService';
import fields from 'src/modules/notifications/importer/notificationsImporterFields';
import { i18n } from 'src/i18n';

const notificationsImporterActions = importerActions(
  'NOTIFICATIONS_IMPORTER',
  selectors,
  NotificationsService.import,
  fields,
  i18n('entities.notifications.importer.fileName'),
);

export default notificationsImporterActions;