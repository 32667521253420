import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/orderItems/importer/orderItemsImporterSelectors';
import OrderItemsService from 'src/modules/orderItems/orderItemsService';
import fields from 'src/modules/orderItems/importer/orderItemsImporterFields';
import { i18n } from 'src/i18n';

const orderItemsImporterActions = importerActions(
  'ORDERITEMS_IMPORTER',
  selectors,
  OrderItemsService.import,
  fields,
  i18n('entities.orderItems.importer.fileName'),
);

export default orderItemsImporterActions;